<template>
  <div>
    <b-card>
      <div class="d-flex justify-content-end pb-1">
        <b-button size="sm" variant="relief-success" v-b-modal.modal-add-product
          ><feather-icon icon="PlusIcon" />เพิ่มอุปกรณ์จัดชุด</b-button
        >
      </div>
      <list-table />
    </b-card>
    <product-kit-add-modal />
  </div>
</template>

<script>
import { BCard, BCardText, BButton } from "bootstrap-vue";
import ListTable from "./component/ListTable.vue";
import ProductKitAddModal from "./component/ProductKit-Add-Modal.vue";

export default {
  components: {
    BCard,
    BCardText,
    BButton,
    ListTable,
    ProductKitAddModal,
  },
};
</script>

<style></style>
