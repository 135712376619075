<template>
  <div>
    <b-modal
      id="modal-add-product"
      ref="modal-add-product"
      title="เพิ่มอุปกรณ์จัดชุด"
      no-close-on-backdrop
      size="lg"
      hide-footer
    >
      <validation-observer ref="addProduct">
        <b-form @submit.prevent="addProduct">
          <!-- ชื่ออุปกรณ์ -->
          <b-form-group label="ชื่อในระบบ">
            <validation-provider
              #default="{ errors }"
              name="Product Name"
              rules="required"
            >
              <b-form-input
                v-model="form.name"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- ชื่อในเว็บ -->
          <b-form-group label="ชื่อในเว็บ">
            <validation-provider
              #default="{ errors }"
              name="Web Name"
              rules="required"
            >
              <b-form-input
                v-model="form.web_name"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Brand -->
          <b-form-group label="ยี่ห้อ">
            <validation-provider
              #default="{ errors }"
              name="Brand"
              rules="required"
            >
              <b-form-input
                v-model="form.brand"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- ค่าเช่า -->
          <b-form-group label="ค่าเช่า">
            <validation-provider
              #default="{ errors }"
              name="Rental Price"
              rules="required"
            >
              <b-form-input
                v-model="form.rental_price"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- ยอดวางประกัน -->
          <b-form-group label="ยอดวางประกัน">
            <validation-provider
              #default="{ errors }"
              name="Deposit Price"
              rules="required"
            >
              <b-form-input
                v-model="form.deposit_1"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- แสดงหน้าเว็บ -->
          <b-form-group>
            <h5>แสดงหน้าเว็บ</h5>
            <div class="demo-inline-spacing">
              <b-form-radio
                v-model="form.web_enable"
                name="some-radios2"
                :value="true"
              >
                แสดงหน้าเว็บ
              </b-form-radio>
              <b-form-radio
                v-model="form.web_enable"
                name="some-radios2"
                :value="false"
              >
                ไม่แสดงหน้าเว็บ
              </b-form-radio>
            </div>
          </b-form-group>

          <!-- การคิดค่าเช่า -->
          <!-- <b-form-group>
            <h5>การคิดค่าเช่า</h5>
            <div class="demo-inline-spacing">
              <b-form-radio
                v-model="form.flat_rate"
                name="some-radios4"
                :value="false"
              >
                ปกติ
              </b-form-radio>
              <b-form-radio
                v-model="form.flat_rate"
                name="some-radios4"
                :value="true"
              >
                ราคาเดียว
              </b-form-radio>
            </div>
          </b-form-group> -->

          <!-- Is GoPro -->
          <b-form-group>
            <h5>GoProlineup</h5>
            <div class="demo-inline-spacing">
              <b-form-radio
                v-model="form.is_gopro"
                name="some-radios5"
                :value="true"
              >
                ใช่
              </b-form-radio>
              <b-form-radio
                v-model="form.is_gopro"
                name="some-radios5"
                :value="false"
              >
                ไม่
              </b-form-radio>
            </div>
          </b-form-group>
          <hr />
          <div class="text-right justify">
            <b-button variant="relief-secondary" class="mr-1" @click="cancel">
              Cancel
            </b-button>
            <b-button
              v-if="!loading"
              variant="relief-success"
              @click="validationForm"
            >
              เพิ่มอุปกรณ์
            </b-button>
            <b-button
              variant="relief-success"
              disabled
              class="mr-1"
              v-if="loading"
            >
              <b-spinner small />
              Loading...
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BForm,
  BModal,
  BButton,
  BFormRadio,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

export default {
  components: {
    BFormGroup,
    BFormInput,
    BForm,
    BModal,
    BButton,
    ValidationObserver,
    ValidationProvider,
    BFormRadio,
  },
  data() {
    return {
      form: {
        web_enable: true,
        admin_enable: true,
        shipable: true,
        flat_rate: false,
        is_gopro: false,
      },
      required,
      loading: false,
    };
  },
  created() {},
  methods: {
    validationForm() {
      this.$refs.addProduct.validate().then((success) => {
        this.form.main_image = "";
        if (success) {
          this.$http({
            method: "POST",
            url: "/product-kit",
            data: this.form,
          }).then((x) => {
            this.$router.push("/product-kit/view/" + x.data._id);
          });
        }
      });
    },
    cancel() {
      this.$refs["modal-add-product"].hide();
    },
  },
};
</script>

<style></style>
