var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"modal-add-product",attrs:{"id":"modal-add-product","title":"เพิ่มอุปกรณ์จัดชุด","no-close-on-backdrop":"","size":"lg","hide-footer":""}},[_c('validation-observer',{ref:"addProduct"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.addProduct($event)}}},[_c('b-form-group',{attrs:{"label":"ชื่อในระบบ"}},[_c('validation-provider',{attrs:{"name":"Product Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"ชื่อในเว็บ"}},[_c('validation-provider',{attrs:{"name":"Web Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.form.web_name),callback:function ($$v) {_vm.$set(_vm.form, "web_name", $$v)},expression:"form.web_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"ยี่ห้อ"}},[_c('validation-provider',{attrs:{"name":"Brand","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.form.brand),callback:function ($$v) {_vm.$set(_vm.form, "brand", $$v)},expression:"form.brand"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"ค่าเช่า"}},[_c('validation-provider',{attrs:{"name":"Rental Price","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.form.rental_price),callback:function ($$v) {_vm.$set(_vm.form, "rental_price", $$v)},expression:"form.rental_price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"ยอดวางประกัน"}},[_c('validation-provider',{attrs:{"name":"Deposit Price","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.form.deposit_1),callback:function ($$v) {_vm.$set(_vm.form, "deposit_1", $$v)},expression:"form.deposit_1"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('h5',[_vm._v("แสดงหน้าเว็บ")]),_c('div',{staticClass:"demo-inline-spacing"},[_c('b-form-radio',{attrs:{"name":"some-radios2","value":true},model:{value:(_vm.form.web_enable),callback:function ($$v) {_vm.$set(_vm.form, "web_enable", $$v)},expression:"form.web_enable"}},[_vm._v(" แสดงหน้าเว็บ ")]),_c('b-form-radio',{attrs:{"name":"some-radios2","value":false},model:{value:(_vm.form.web_enable),callback:function ($$v) {_vm.$set(_vm.form, "web_enable", $$v)},expression:"form.web_enable"}},[_vm._v(" ไม่แสดงหน้าเว็บ ")])],1)]),_c('b-form-group',[_c('h5',[_vm._v("GoProlineup")]),_c('div',{staticClass:"demo-inline-spacing"},[_c('b-form-radio',{attrs:{"name":"some-radios5","value":true},model:{value:(_vm.form.is_gopro),callback:function ($$v) {_vm.$set(_vm.form, "is_gopro", $$v)},expression:"form.is_gopro"}},[_vm._v(" ใช่ ")]),_c('b-form-radio',{attrs:{"name":"some-radios5","value":false},model:{value:(_vm.form.is_gopro),callback:function ($$v) {_vm.$set(_vm.form, "is_gopro", $$v)},expression:"form.is_gopro"}},[_vm._v(" ไม่ ")])],1)]),_c('hr'),_c('div',{staticClass:"text-right justify"},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"relief-secondary"},on:{"click":_vm.cancel}},[_vm._v(" Cancel ")]),(!_vm.loading)?_c('b-button',{attrs:{"variant":"relief-success"},on:{"click":_vm.validationForm}},[_vm._v(" เพิ่มอุปกรณ์ ")]):_vm._e(),(_vm.loading)?_c('b-button',{staticClass:"mr-1",attrs:{"variant":"relief-success","disabled":""}},[_c('b-spinner',{attrs:{"small":""}}),_vm._v(" Loading... ")],1):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }